import React, { useEffect, useState } from "react";
import { getByRepairshopIdUserMechanics, updateUserMechanic, deleteUserMechanic, createUserMechanic } from "../../../services/API/User.Services";
import ModalComponent from "../../../components/Modal.Component";
import { getStorageObject } from "../../../utils/LocalStorage";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, TextField, IconButton, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { useToast } from "../../../components/toastComponent/toast.Actions";

const EditMechanicModal = ({ mechanic, setEditModalIsOpen, handleUpdateUserMechanic }) => {
  const { openCustomToast } = useToast();

  const [mechanicToAddFirstName, setMechanicToAddFirstName] = useState(mechanic.name_first);
  const [mechanicToAddLastName, setMechanicToAddLastName] = useState(mechanic.name_last);
  const [mechanicToAddEmail, setMechanicToAddEmail] = useState(mechanic.email || "");
  const [mechanicToAddPhone, setMechanicToAddPhone] = useState(mechanic.phone_number || "");

  const handleUpdateMechanic = async () => {
    const token = getStorageObject("token");

    const mechanicData = {
      id: mechanic.id,
      name_first: mechanicToAddFirstName,
      name_last: mechanicToAddLastName || null,
      email: mechanicToAddEmail || null,
      phone_number: mechanicToAddPhone || null,
      id_user_repairshop: getStorageObject("repairShopId"),
    };

    await handleUpdateUserMechanic(mechanicData);
  };

  return (
    <ModalComponent>
      <Stack backgroundColor={"white"} sx={{ padding: "20px" }} position={"relative"} alignItems={"center"}>
        <IconButton sx={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={() => setEditModalIsOpen(false)}>
          <CloseIcon />
        </IconButton>
        <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Nombre" variant="standard" value={mechanicToAddFirstName} onChange={(e) => setMechanicToAddFirstName(e.target.value)} />
        <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Apellido" variant="standard" value={mechanicToAddLastName} onChange={(e) => setMechanicToAddLastName(e.target.value)} />
        <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Email" variant="standard" value={mechanicToAddEmail} onChange={(e) => setMechanicToAddEmail(e.target.value)} />
        <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Teléfono" variant="standard" value={mechanicToAddPhone} onChange={(e) => setMechanicToAddPhone(e.target.value)} />
        <Button onClick={handleUpdateMechanic} sx={{ width: "80%", marginTop: "10px", marginBottom: "10px" }} variant="contained">
          Actualizar
        </Button>
      </Stack>
    </ModalComponent>
  );
};

const MechanicComponent = ({ setMechanicToEdit, mechanic, handleUpdateUserMechanic, handleDeleteUserMechanic, setEditModalIsOpen }) => {
  const { openCustomToast } = useToast();

  const handleEditMechanic = () => {
    setMechanicToEdit(mechanic);
    setEditModalIsOpen(true);
  };

  return (
    <ListItem
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", backgroundColor: "#f5f5f5", boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.1)" }}
      key={mechanic.id_user}
    >
      <Stack alignItems={"center"} gap={"10px"} direction={"row"}>
        <Typography style={{ color: "black", fontWeight: "300", fontFamily: "Inter", fontSize: "0.9rem" }}>
          {mechanic.name_first} {mechanic.name_last}
        </Typography>
        <Typography style={{ color: "black", fontWeight: "600", fontFamily: "Inter", fontSize: "0.7rem" }}>
          {mechanic?.phone_number} {mechanic.email}
        </Typography>
      </Stack>

      <Stack direction={"row"} sx={{ gap: "10px" }}>
        <IconButton onClick={handleEditMechanic}>
          <EditIcon style={{ color: "black" }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteUserMechanic(mechanic.id)}>
          <DeleteIcon style={{ color: "black" }} />
        </IconButton>
      </Stack>
    </ListItem>
  );
};

const MechanicsComponent = () => {
  const { openCustomToast } = useToast();

  const token = getStorageObject("token");

  const [mechanicsState, setMechanicsState] = useState([]);

  // ADD MECHANIC STATES
  const [mechanicToAddFirstName, setMechanicToAddFirstName] = useState();
  const [mechanicToAddLastName, setMechanicToAddLastName] = useState();
  const [mechanicToAddEmail, setMechanicToAddEmail] = useState();
  const [mechanicToAddPhone, setMechanicToAddPhone] = useState();

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [mechanicToEdit, setMechanicToEdit] = useState(null);

  const [mechanicEditMode, setMechanicEditMode] = useState(false);

  useEffect(() => {
    getByRepairshopIdUserMechanics(token).then((response) => {
      if (Array.isArray(response.data)) {
        setMechanicsState(response.data);
        console.log("Mechanics: ", response);
      } else {
        console.error("Unexpected response: ", response);
        setMechanicsState([]);
      }
    });
  }, []);

  const handleUpdateUserMechanic = async (_body) => {
    try {
      const response = await updateUserMechanic(token, _body);

      console.log("RESPUESTA BRUTGAL", response);

      if (response.ok) {
        console.log("MUY BIEN");

        openCustomToast("Mecánico actualizado correctamente", "success");

        setMechanicsState((prevMechanicsState) => prevMechanicsState.map((mechanic) => (mechanic.id_user_mechanic === _body.id_user_mechanic ? { ...mechanic, ..._body } : mechanic)));

        setEditModalIsOpen(false);
      } else {
        openCustomToast("Ha ocurrido un error al actualizar el mecánico", "error");
      }
    } catch (error) {
      console.error("Error al actualizar mecánico:", error);
      openCustomToast("Error inesperado. Por favor, intenta de nuevo", "error");
    }
  };

  const handleDeleteUserMechanic = async (id_user_mechanic) => {
    try {
      console.log("DELETE", token);
      const response = await deleteUserMechanic(token, id_user_mechanic);

      if (response.ok) {
        openCustomToast("Mecánico eliminado", "success");
        setMechanicsState((prevMechanicsState) => prevMechanicsState.filter((mechanic) => mechanic.id !== id_user_mechanic));
        setEditModalIsOpen(false);
      } else {
        openCustomToast("Ha ocurrido un error", "error");
      }
    } catch (error) {
      console.error("Error al eliminar mecánico:", error);
      openCustomToast("Error inesperado. Por favor, intenta de nuevo", "error");
    }
  };

  const handleCreateUserMechanic = async () => {
    if (!mechanicToAddFirstName) {
      openCustomToast("Introduce al menos el nombre", "error");
      return;
    }

    const mechanicData = {
      name_first: mechanicToAddFirstName,
      name_last: mechanicToAddLastName || null,
      email: mechanicToAddEmail || null,
      phone_number: mechanicToAddPhone || null,
      id_user_repairshop: getStorageObject("repairShopId"),
    };

    try {
      const createdMechanic = await createUserMechanic(token, mechanicData);

      if (createdMechanic && createdMechanic.ok) {
        openCustomToast("Mecánico creado correctamente", "success");
        setMechanicsState((prevMechanicsState) => [...prevMechanicsState, createdMechanic.data]);
        setMechanicToAddFirstName("");
        setMechanicToAddLastName("");
        setMechanicToAddEmail("");
        setMechanicToAddPhone("");
      } else {
        openCustomToast("Ha ocurrido un error al crear el mecánico", "error");
      }
    } catch (error) {
      console.error("Error al crear mecánico:", error);
      openCustomToast("Error inesperado. Por favor, intenta de nuevo", "error");
    }
  };

  return (
    <>
      {editModalIsOpen && <EditMechanicModal mechanic={mechanicToEdit} setEditModalIsOpen={setEditModalIsOpen} handleUpdateUserMechanic={handleUpdateUserMechanic} />}
      <Stack alignItems={"center"} justifyContent={"space-between"} gap={"10px"} p={"0"} width={"100%"} margin={"20px 0"} boxShadow={"0 0 20px 0 rgba(0, 0, 0, 0.2)"}>
        <Stack gap={"10px"} sx={{ marginTop: "10px", padding: "0 5px" }} width={"95%"} alignItems={"center"} justifyContent={"center"} direction={"row"}>
          <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Nombre" variant="standard" value={mechanicToAddFirstName} onChange={(e) => setMechanicToAddFirstName(e.target.value)} />
          <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Apellido" variant="standard" value={mechanicToAddLastName} onChange={(e) => setMechanicToAddLastName(e.target.value)} />
          <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Email" variant="standard" value={mechanicToAddEmail} onChange={(e) => setMechanicToAddEmail(e.target.value)} />
          <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Teléfono" variant="standard" value={mechanicToAddPhone} onChange={(e) => setMechanicToAddPhone(e.target.value)} />
          <IconButton onClick={() => handleCreateUserMechanic()}>
            <AddIcon />
          </IconButton>
        </Stack>
        <Stack width={"100%"}>
          {mechanicsState.map((mechanic, index) => (
            <MechanicComponent
              setMechanicToEdit={setMechanicToEdit}
              setEditModalIsOpen={setEditModalIsOpen}
              setMechanicEditMode={setMechanicEditMode}
              key={index}
              mechanic={mechanic}
              handleUpdateUserMechanic={handleUpdateUserMechanic}
              handleDeleteUserMechanic={handleDeleteUserMechanic}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default MechanicsComponent;
