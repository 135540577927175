import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Container, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { getByUserIdVehicles } from "../../services/API/Vehicle.Services";
import CustomDatePicker, { customDatePicker } from "./Components/Datepicker.Component.jsx";
import { getStorageObject } from "../../utils/LocalStorage.js";
import { getAllManufacturers } from "../../services/API/Manufacturer.Services.js";
import { getAllModels } from "../../services/API/Model.Services.js";
import { getByDayRepairshopAppointments, postAppointment } from "../../services/API/Schedule.Services.js";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoadingComponent from "../../components/Loading.Component.jsx";

const AppointmentPage = () => {
  const [steps, setSteps] = useState(1);
  const [userOwnerId, setUserOwnerId] = useState("7");
  const [repairshopId, setRepairshopId] = useState("1");
  const [responsiveSmallState, setResponsiveSmallState] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [allModels, setAllModels] = useState([]);
  const [repairshopAppointements, setRepairshopAppointments] = useState([]);
  const [horarios, setHorarios] = useState();

  const [maintinanceServiceDetails, setMaintinanceServiceDetails] = useState();
  const [repairServiceDetails, setRepairServiceDetails] = useState();
  const [repairServiceDetails2, setRepairServiceDetails2] = useState();

  const [userOwnerData, setUserOwnerData] = useState({});
  const [repairshopData, setRepairshopData] = useState({});

  const [loadingAppointments, setLoadingAppointments] = useState(true);

  const [selectedDay, setSelectedDay] = useState();
  const [selectedHour, setSelectedHour] = useState();
  const [serviceType, setServiceType] = useState();

  const [selectedServices, setSelectedServices] = useState(() => []);

  const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] = useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState();
  const [selectedVehicleYear, setSelectedVehicleYear] = useState();
  const [selectedVehiclePlate, setSelectedVehiclePlate] = useState();

  const [isCompleted, setIsCompleted] = useState(false);

  const [loadingCreateAppointment, setLoadingCreateAppointment] = useState(false);

  const generateSubjectName = () => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    if (selectedVehicle && selectedVehicle !== "otro") {
      return `${capitalize(selectedVehicle.vehicle_manufacturer.name)} ${capitalize(selectedVehicle.vehicle_model.name)} ${selectedVehicle.plate}`;
    } else if (selectedVehicle === "otro") {
      return `${capitalize(selectedVehicleManufacturer)} ${capitalize(selectedVehicleModel)} ${selectedVehiclePlate}`;
    }
  };

  const handleCreateAppointment = async () => {
    setLoadingCreateAppointment(true);
    function formatDateTime(selectedDay, selectedHour) {
      const selectedDayDate = new Date(selectedDay);

      const year = selectedDayDate.getUTCFullYear();
      const month = selectedDayDate.getUTCMonth();
      const day = selectedDayDate.getUTCDate();

      const utcDate = new Date(year, month, day, selectedHour, 0, 0);

      const fYear = utcDate.getUTCFullYear();
      const fMonth = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
      const fDay = String(utcDate.getUTCDate()).padStart(2, "0");
      const fHour = String(utcDate.getUTCHours()).padStart(2, "0");
      const minute = "00";
      const second = "00";

      return `${fYear}-${fMonth}-${fDay} ${fHour}:${minute}:${second}+00`;
    }

    console.log(formatDateTime(selectedDay, selectedHour));

    const appointmentData = {
      id_vehicle: selectedVehicle.id,
      id_user_repairshop: repairshopId,
      start_time: formatDateTime(selectedDay, selectedHour),
      end_time: formatDateTime(selectedDay, selectedHour + 1),
      id_user_owner: userOwnerId,
      subject: generateSubjectName() || "",
    };

    const reqData = { id_user_repairshop: repairshopId, appointment_data: appointmentData };

    try {
      const token = getStorageObject("token");
      await postAppointment(token, reqData);
    } catch (error) {}

    setLoadingCreateAppointment(false);
  };

  const handleServicesChange = (event, newService) => {
    if (newService.length) {
      setSelectedServices(newService);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getStorageObject("token");

      if (userOwnerId) {
        const response = await getByUserIdVehicles(token, userOwnerId);
        setVehicles(response.data);
        if (response.data.length === 1) {
          setSelectedVehicle(response.data[0].Id);
        }
      }

      const [manufacturersResponse, modelsResponse] = await Promise.all([getAllManufacturers(token), getAllModels(token)]);

      const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

      const transformedModels = modelsResponse.data.map((model) => ({
        id: model.id,
        value: model.id,
        label: model.name,
        manufacturer: model.id_vehicle_manufacturer,
      }));

      const transformedManufacturers = manufacturersResponse.data.map((manufacturer) => ({
        id: manufacturer.id,
        value: manufacturer.id,
        label: capitalize(manufacturer.name),
      }));

      setAllManufacturers(transformedManufacturers);
      setAllModels(transformedModels);
    };

    fetchData();
  }, [userOwnerId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setResponsiveSmallState(true);
      } else {
        setResponsiveSmallState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedDay) {
      setSelectedHour(null);
      setLoadingAppointments(true);
      const token = getStorageObject("token");

      const utcDay = new Date(selectedDay).toISOString();

      const reqData = { day: utcDay, id_user_repairshop: 1 };

      try {
        getByDayRepairshopAppointments(token, reqData).then(({ data }) => {
          setRepairshopAppointments(data?.occupiedHours);
          setLoadingAppointments(false);
        });
      } catch (error) {
        setLoadingAppointments(false);
      }
    }
  }, [selectedDay]);

  const handleSelectHour = (event, hour) => {
    console.log("HORA: ", hour);
    setSelectedHour(hour);
    setSteps(2);
  };

  const handleSelectManufacturer = (event, manufacturerId) => {
    setSelectedVehicleManufacturer(manufacturerId);
  };

  const handleSelectModel = (event, modelId) => {
    setSelectedVehicleModel(modelId);
  };

  const handleSelectVehicle = (event, vehicle) => {
    console.log(vehicle);
    setSelectedVehicle(vehicle);
  };

  useEffect(() => {
    const workDayMorningStartTime = 8;
    const workDayMorningEndTime = 14;

    const workDayEveningDayStartTime = 16;
    const workEveningDayEndTime = 21;

    const horariosArray = [];

    for (let hora = workDayMorningStartTime; hora <= workDayMorningEndTime; hora++) {
      horariosArray.push(hora);
    }

    for (let hora = workDayEveningDayStartTime; hora <= workEveningDayEndTime; hora++) {
      horariosArray.push(hora);
    }

    setHorarios(horariosArray);
  }, []);

  if (loadingCreateAppointment) {
    return <LoadingComponent />;
  }

  if (isCompleted) {
    return <CompletedComponent />;
  }

  return (
    <motion.div style={{ overflow: "scroll" }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
      <Container
        sx={{ width: "fit-content", maxWidth: "600px", height: "calc(100vh - 10px)", width: "100%", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center", justifyContent: "center" }}
      >
        {/* ----------------------------------------------DIA Y HORA---------------------------------------------------- */}

        {steps === 1 && (
          <>
            <img style={{ height: "35px", marginRight: "0px" }} src="../../img/drivi_logo.svg" alt="" />
            <Typography variant="h3" sx={{ fontWeight: "100", color: "#e94e1b" }}>
              Crea tu cita
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "200" }}>
              Selecciona un horario para tu <span style={{ color: "#e94e1b" }}>cita</span>
            </Typography>

            <Stack direction={responsiveSmallState ? "column" : "row"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.15)", alignItems: "center", justifyContent: "center" }}>
              <CustomDatePicker setSelectedDay={setSelectedDay} />

              {selectedDay && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} style={{ overflow: "hidden" }}>
                  <Stack direction={"row"}>
                    <ToggleButtonGroup
                      sx={{ flexWrap: "wrap", justifyContent: "center", maxWidth: "220px", padding: "30px 15px", gap: "10px", alignItems: "center", justifyContent: "center" }}
                      value={selectedHour}
                      exclusive
                      onChange={handleSelectHour}
                      aria-label="text alignment"
                    >
                      {loadingAppointments ? (
                        <Stack sx={{ alignItems: "center", justifyContent: "center" }} minWidth={"220px"}>
                          <CircularProgress />
                        </Stack>
                      ) : (
                        horarios.map((horario, index) => (
                          <ToggleButton
                            disabled={repairshopAppointements.some((app) => app == horario)}
                            value={horario}
                            sx={{
                              borderRadius: "0px",
                              width: "60px",
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
                            }}
                            key={horario}
                          >
                            {horario}:00
                          </ToggleButton>
                        ))
                      )}
                    </ToggleButtonGroup>
                  </Stack>
                </motion.div>
              )}
            </Stack>
          </>
        )}
        {/* ----------------------------------------------VEHICULO---------------------------------------------------- */}
        {steps === 2 && (
          <>
            <Typography variant="h4" sx={{ fontWeight: "200" }}>
              {vehicles && vehicles.length > 0 ? (
                <>
                  Selecciona el <span style={{ color: "#e94e1b" }}>vehículo</span>
                </>
              ) : (
                <>
                  Pon los detaller del <span style={{ color: "#e94e1b" }}>vehículo</span>
                </>
              )}
            </Typography>
            {vehicles && vehicles.length > 0 && (
              <Stack>
                <ToggleButtonGroup value={selectedVehicle} exclusive onChange={handleSelectVehicle} aria-label="text alignment">
                  {vehicles &&
                    vehicles.map((vehicle) => (
                      <ToggleButton key={vehicle.id} value={vehicle}>
                        {vehicle.vehicle_manufacturer.name || ""} {vehicle.vehicle_model.name || ""} {vehicle.plate || ""}
                      </ToggleButton>
                    ))}
                  <ToggleButton value="otro">Otro</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            )}
            {!vehicles ||
              (vehicles && vehicles.length === 0) ||
              (selectedVehicle == "otro" && (
                <Stack gap={"15px"}>
                  <Autocomplete
                    value={selectedVehicleManufacturer}
                    onChange={handleSelectManufacturer}
                    sx={{ "& fieldset": { borderRadius: "0" } }}
                    options={allManufacturers}
                    // required
                    renderInput={(params) => <TextField {...params} label="Fabricante" variant="outlined" />}
                  />
                  {selectedVehicleManufacturer && (
                    <Autocomplete
                      sx={{ "& fieldset": { borderRadius: "0" }, padding: "0" }}
                      onChange={(e, newModel) => setSelectedVehicleModel(newModel)}
                      options={allModels.filter((model) => model.manufacturer === selectedVehicleManufacturer?.id)}
                      value={selectedVehicleModel}
                      renderInput={(params) => <TextField {...params} label="Modelo" variant="outlined" />}
                    />
                  )}

                  <TextField value={selectedVehicleYear} onChange={(e) => setSelectedVehicleYear(e.target.value)} label="Año" sx={{ "& fieldset": { borderRadius: "0" } }}></TextField>

                  <TextField value={selectedVehiclePlate} onChange={(e) => setSelectedVehiclePlate(e.target.value)} label="Matricula" sx={{ "& fieldset": { borderRadius: "0" } }}></TextField>
                </Stack>
              ))}
            <Stack sx={{ width: "100%", justifyContent: "center", flexDirection: "row", gap: "10px" }}>
              <Button onClick={() => setSteps(1)}>Volver</Button>
              <Button disabled={!selectedVehicle || (selectedVehicle === "otro" && (!selectedVehicleModel || !selectedVehicleYear || !selectedVehiclePlate))} onClick={() => setSteps(3)} variant="contained">
                Continuar
              </Button>
            </Stack>
          </>
        )}

        {/* ---------------------------------------------SERVICIO---------------------------------------------------- */}

        {steps === 3 && (
          <>
            {" "}
            <Typography>¿Qué tipo de servicio necesitarás?</Typography>
            <ToggleButtonGroup value={selectedServices} onChange={handleServicesChange} aria-label="text alignment">
              <ToggleButton value="reparacion">Reparación</ToggleButton>
              <ToggleButton value="mantenimiento">Mantenimiento</ToggleButton>
            </ToggleButtonGroup>
            {selectedDay && selectedHour && (
              <Stack spacing={2}>
                {selectedDay && selectedHour && (
                  <Stack spacing={2}>
                    {selectedServices.some((service) => service === "reparacion") && (
                      <>
                        <Typography>¿Podría describir exactamente qué problema presenta el vehículo?</Typography>
                        <TextField value={repairServiceDetails} onChange={(e) => setRepairServiceDetails(e.target.value)} multiline rows={2}></TextField>
                        <Typography>¿Con qué frecuencia ocurre el problema? ¿Es constante o intermitente?</Typography>
                        <TextField value={repairServiceDetails2} onChange={(e) => setRepairServiceFrequency(e.target.value)} multiline rows={2}></TextField>
                      </>
                    )}

                    {selectedServices.some((service) => service === "mantenimiento") && (
                      <>
                        <Typography>Explica brevemente el mantenimiento que deseas realizar</Typography>
                        <TextField multiline rows={2}></TextField>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
            <Stack sx={{ width: "100%", justifyContent: "center", flexDirection: "row", gap: "10px" }}>
              <Button onClick={() => setSteps(2)}>Volver</Button>
              <Button onClick={() => setSteps(4)} variant="contained">
                Continuar
              </Button>
            </Stack>
          </>
        )}

        {/* ----------------------------------------------RESUMEN---------------------------------------------------- */}

        {steps == 4 && (
          <>
            <Stack gap={"10px"} alignItems={"center"} justifyContent={"center"}>
              <Typography variant="h4" sx={{ fontWeight: "200", marginBottom: "10px" }}>
                Resumen de tu cita
              </Typography>
              <Stack sx={{ boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.2)", padding: "20px" }} gap={"15px"}>
                <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <AccessTimeIcon />
                  <Typography sx={{ fontWeight: "200", fontSize: "1.2rem" }}>
                    La cita es el dia{" "}
                    <span style={{ color: "#e94e1b", fontWeight: "bold" }}>
                      {new Date(selectedDay).toLocaleDateString()}, a las {selectedHour}:00
                    </span>
                  </Typography>
                </Stack>
                <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <DirectionsCarIcon />
                  <Typography sx={{ fontWeight: "200", textTransform: "capitalize", fontSize: "1.2rem" }}>{generateSubjectName()}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ width: "100%", justifyContent: "center", flexDirection: "row", gap: "10px" }}>
              <Button onClick={() => setSteps(2)}>Volver</Button>
              <Button onClick={handleCreateAppointment} variant="contained">
                Agendar cita
              </Button>
            </Stack>
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default AppointmentPage;
